import glenn from '../assets/glenn.webp'
import ed from '../assets/ed.webp'
import georgina from '../assets/georgina.webp'
import no_picture from '../assets/no_picture.png'
import bikeLogo from '../assets/TBI-white.svg'
import bike from '../assets/bike.webp'
import vanLogo from '../assets/TVI-white.svg'
import van from '../assets/van.webp'
import motorhomeLogo from '../assets/TMI-white.svg'
import motorhome from '../assets/motorhome.webp'



export const sitemap = {
    title: 'Sitemap',
    bgColor: '#e9f6fe',
    list: [
        {
            contentTitle: 'Pages',
        },
        {
            text: '[About Us](/about-us)',
        },
        {
            text: '[Contact Us](/contact-us)'
        },
        {
            text: '[Home page](/)'
        },
        {
            text: '[Our Brands](/our-brands)'
        },
        {
            text: '[Privacy and Cookie Policy](/privacy-and-cookie-policy)'
        },
        {
            text: '[Sitemap](/sitemap)'
        },
    ]
}

export const privacy = {
    title: 'Privacy and Cookie Policy',
    bgColor: '#e9f6fe',
    paragraphSubtitle: 'ICE Internet Comparison Experts Limited (“ICE”)',
    description: [
        {
            contentTitle: 'Privacy Notice',
        },
        {
            text: 'This privacy notice tells you what to expect when our organisation collects personal data, and how to contact us should you wish to discuss any aspects of how we handle that data.',
        },
        {
            contentTitle: 'Who we are'
        },
        {
            text: 'ICE is incorporated in Jersey (company number 107715) and is authorised by the Jersey Financial Services Commission for the purposes of providing General Insurance Mediation Business. ICE operates under a number of brand names in the UK, including The Insurers, The Van Insurer, The Bike Insurer, The Motorhome Insurer and The Caravan Insurer.'
        },
        {
            text: 'ICE is the owner of the price comparison websites namely **[www.theinsurers.co.uk](www.theinsurers.co.uk)**, **[www.thebikeinsurer.co.uk](www.thebikeinsurer.co.uk)**, **[www.thevaninsurer.co.uk](www.thevaninsurer.co.uk)**, **[www.themotorhomeinsurer.co.uk](www.themotorhomeinsurer.co.uk)** and **[www.thecaravaninsurer.co.uk](www.thecaravaninsurer.co.uk)**. The websites provide a price comparison service in respect of general insurance policies to UK residents only and are operated by Vast Visibility Limited (“Vast”), a company incorporated in the UK and authorised by the Financial Conduct Authority (“FCA”) for General Insurance Mediation business.'
        },
        {
            contentTitle: 'Data Protection Contact (“DPC”)'
        },
        {
            text: 'Although the nature of our business does not oblige us to appoint a Data Protection Officer, we do have a central point of contact for data protection queries.'
        },
        {
            text: 'The best means of contact for any query regarding data protection is via email at **[georgina.buesnel@icecomparison.com](mailto:georgina.buesnel@icecomparison.com)**. Although data protection queries to anyone in ICE will be forwarded to the DPC by the recipient, a direct email assures a timely response. If you have any questions or concerns regarding how we work with personal data, please don’t hesitate to contact us via the DPC.'
        },
        {
            text: 'We store and process only the data that the law allows us to hold in order to engage with you. For individuals this would include name, address, date of birth etc. For companies this would include memorandum & articles of incorporation, certificate of incorporation, register of directors, detail of beneficial owners etc. We delete the data when it is no longer required.'
        },
        {
            text: 'Unless otherwise stated, we do not transfer to or allow anyone else to use your data and the data is stored on our servers in Jersey. If you would like more detail, you are welcome to get in touch.'
        },
        {
            contentTitle: 'Employee data'
        },
        {
            text: 'We store personal data on our employees so that we can run the company and pay our employees. This includes names and contact details, pre-employment screening information, performance and disciplinary data, and health information and bank details; it also includes emergency contact and next-of-kin information as provided to us by each employee. We retain data for former employees only for as long as the law allows.'
        },
        {
            contentTitle: 'Personal data'
        },
        {
            text: 'We store and process data from individuals and companies in order that we can correspond with, take payments from and make payment to suppliers, consultants etc. When we have not transacted business with you for five years or longer, we will delete your personal data from our systems.'
        },
        {
            contentTitle: 'Information Security'
        },
        {
            text: 'Our IT systems are located in secure data centres to protect them against theft and environmental risks (flood, fire, power cuts, etc.). All our computer systems run up to date anti-virus software, and system updates are applied regularly to protect against potential security problems. All user login IDs on our systems are restricted so that each user has access only to the data that he or she requires.'
        },
        {
            contentTitle: 'Security on mobile devices'
        },
        {
            text: 'All our laptops’ hard drives are fully encrypted, so the data held on them is safe should one be lost or stolen. Our staff have secure access to their work email out of office hours from their work mobile phones which are password protected.'
        },
        {
            contentTitle: 'Internet interaction'
        },
        {
            text: 'We monitor any emails sent to us, including file attachments, for security threats such as phishing scams, viruses or other malicious software. Please note that you have a responsibility to ensure that any email you send us is within the bounds of the law.'
        },
        {
            contentTitle: 'Contact for data protection purposes'
        },
        {
            text: 'You have a number of rights under the laws of data protection. As mentioned earlier, please contact the Data Protection Contact by email or letter if you have any queries or concerns. We retain a log of requests that we receive and remove entries when they are a year old.'
        },
        {
            contentTitle: 'Right of access'
        },
        {
            text: 'You can contact us to request a copy of any personal data we hold about you on our systems or in our files, along with information about what we use it for. We must respond to you in a reasonable time, and always within a month. Unless the request is particularly complex or onerous there’s no cost to you for making these requests.'
        },
        {
            contentTitle: 'Right to rectification'
        },
        {
            text: 'We must ensure that the data we hold about you is accurate. If you tell us that something is wrong, we will correct it and then confirm to you that we have done so.'
        },
        {
            contentTitle: 'Right to erasure'
        },
        {
            text: 'If you ask us to erase your personal data, we must do so unless there’s a lawful and legitimate reason for us to keep it. For example, we need some of your personal data in order to satisfy our contract with you (for example to interact with you or to send you bills). We shall keep just the information we need for those purposes.'
        },
        {
            contentTitle: 'Right to restriction of processing'
        },
        {
            text: 'If there is some dispute between you and us regarding the use of your personal data, you have the right to ask us to restrict the processing of your data. This means we can continue to store it but we cannot do anything else with it until the dispute is resolved. We shall inform you prior to beginning processing once the restriction has been removed.'
        },
        {
            contentTitle: 'Our recruitment process'
        },
        {
            text: 'We are the data controller for any information you provide as part of our recruitment process. All of the information you provide during the process will only be used for the purpose of processing your application, or to fulfil legal or regulatory requirements if necessary. We will not share any of the information you provide during the recruitment process for any other purpose and your data will be stored on our IT systems in Jersey. We may look up applicant’ profiles on social media, though we do not copy that information or store it on our systems.'
        },
        {
            text: 'We will use the details you provide to us to contact you to progress your application. We will use the other information you provide to assess your suitability for the role you have applied for. The information we ask for is used to assess your suitability for employment: you do not have to provide what we ask for, but it might affect your application if you do not. Unsuccessful applicants’ data will be retained for no more than a year and then deleted.'
        },
        {
            contentTitle: 'Cookies & Our Cookie Policy'
        },
        {
            text: 'A cookie is a small text file that is placed on your hard disk by a web page server. Cookies cannot be used to harm your computer, they cannot run programs or deliver viruses to your computer. Cookies are uniquely assigned to your computer, and can only be read by a web server in the domain that issued the cookie to you.'
        },
        {
            text: 'Our cookies don’t store sensitive or personally identifiable information such as your name, address or credit card details: they simply hold a unique ‘key’. We do use encrypted information gathered from them to help improve your experience of the site. For example, they help us to identify and resolve errors, or to help us work out the best user interface for our insurance process.'
        },
        {
            text: 'Cookies can be referred to as session cookies or persistent cookies, depending on how long they are used:'
        },
        {
            text: '- Session cookies only last for your online session and disappear from your computer or device when you close your browser.'
        },
        {
            text: '- Persistent cookies stay on your computer or device after the browser has been closed and last for the period of time specified in the cookie. These persistent cookies may be checked each time you visit the site where the cookie was generated or, in the case of third-party cookies, from another site that also uses the same service.'
        },
        {
            text: 'Below is a list of the main cookies we use, and what we use them for.'
        },
        {
            contentTitle: 'Essential cookies'
        },
        {
            text: 'These are cookies that are enabled for all our customers to ensure that our website works properly.'
        },
        {
            text: 'Google Analytics (any cookie beginning with _utm) – These cookies allows us to collect anonymous information about how visitors are using our website including where they drop-off and which device/browser they came from.'
        },
        {
            text: 'Google Analytics (Demographics and Interest Reporting) – This cookie allows us to use anonymous data from Google’s interest based advertising or third party audience data, including demographic information about our visitors including gender, age group and interests.'
        },
        {
            text: 'Visibility Statistics – We use Visibility Statistics to analyse how users interact with our website and the content found within it. Mouse movements, mouse clicks, page scrolling and text entered into search boxes and website forms may be recorded for statistical reporting purposes and to improve site usability. The data compiled is for internal use only.'
        },
        {
            text: 'Google AdWords, DoubleClick, Bing Ads, OMG, Affiliate Window, VE Interactive, Marin, Facebook – These third party cookies show us how you found our website and which website you came from. This helps us know which of our online marketing channels is most effective. They also allow us to reward some external websites for directing you to us.'
        },
        {
            contentTitle: 'Non-essential cookies'
        },
        {
            text: 'We use these cookies for online marketing purposes, the website works without them but we can’t improve our online marketing effectiveness without them. These cookies can be disabled if you don’t want to use them.'
        },
        {
            text: 'AdWords Retargeting – These third party cookies let us serve personalised adverts to you when you’re browsing other websites e.g. reminding you to return to complete your quote. You can control advert personalisation on Google and partner websites here: **[https://adssettings.google.com/](https://adssettings.google.com/)**'
        },
        {
            text: 'DoubleClick Retargeting – These third party cookies let us serve personalised adverts to you when you’re browsing other websites e.g. reminding you to return to complete your quote. You can control advert personalisation on Google and partner websites here: **[https://adssettings.google.com/](https://adssettings.google.com/)**'
        },
        {
            text: 'Facebook Retargeting – These third party cookies let us serve personalised adverts to you when you’re browsing Facebook e.g. reminding you to return to complete your quote. You can control advert personalisation on Facebook here: **[https://en-gb.facebook.com/ads/settings](https://en-gb.facebook.com/ads/settings)**'
        },
        {
            text: 'The advertisements that you see displayed on our sites are delivered on our behalf by certain third party advertising companies. No information which identifies you, for example your name, address, email address or telephone number, is used to provide this advertising. In order to display advertising most likely to be relevant to you, these third party advertisers may place or recognize a unique cookie on your browser. This cookie does not collect personal information about you nor is it linked to any identifiable information about you. By using the Services you are consenting to the use of these third party cookies for these purposes.'
        },
        {
            contentTitle: 'Complaints'
        },
        {
            text: 'This privacy notice is designed to be clear and concise. We are happy to provide any additional information you need: please contact us via email or phone using the contact details in the Data protection section, above.'
        },
        {
            text: 'Should you have any cause for complaint, please write to us at:'
        },
        {
            address: 'Data Protection Contac'
        },
        {
            address: 'ICE Internet Comparison Experts Limited, IFC 5'
        },
        {
            address: 'St Helier, Jersey JE1 1ST'
        },

        {
            text: 'If you are dissatisfied with the way in which your complaint has been handled you may contact your local data protection supervisory authority, or write to our local Information Commissioner:'
        },
        {
            address: 'Office of the Information Commissioner'
        },
        {
            address: 'Brunel House, Old Street, St Helier, Jersey JE2 3RG'
        }
    ]
}

export const about = {
    title: 'About Us',
    subtitle: 'Our goal is to find every customer their cheapest insurance price in the easiest possible way!',
    bgColor: '#ffffff',
    column: true,
    left: [
        {
            text: 'Established in March 2011, ICE manages a number of price comparison websites for general insurance in the automotive sector.'
        },
        {
            text: 'The team at ICE are focused on the customer outcome, aiming to provide a simple, quick and cost-effective solution to obtaining insurance that leaves them feeling happy and satisfied with their purchasing experience.'
        },
    ],
    right: [
        {
            text: 'We partner with a leading provider of technology solutions in the UK insurance marketplace, Vast Visibility Ltd, who help ensure that we remain at the forefront of consumer price comparison technology for the benefit of our diverse range of customers.'
        }
    ]
}

export const team = {
    noPadding: true,
    bgColor: '#e9f6fe',
    paragraphSubtitle: 'Meet the team',
    image: true,
    content: [
        {
            image: glenn,
            name: 'Glenn Timms',
            role: 'Chair & Non-Executive Director',
            description: 'Following an MBA and Chartered Accounting business apprenticeship, Glenn spent the first thirteen years of his career in the corporate finance and strategy advisory and consulting world, latterly as a partner with PWC. Thereafter, Glenn worked in two large corporate environments for five years, including as Head of Corporate Finance and Strategy at what is now Diageo, as well as serving as an Operating Partner at a large private equity firm. More recently, Glenn has served as CEO of five business over nineteen years, leading a number of successful realisations for private equity investors.'
        },
        {
            image: ed,
            name: 'Ed Bevis, Chartered MCSI',
            role: 'Commercial & Marketing Director',
            description: 'Ed has enjoyed a successful 29 year career in the financial services industry having held a variety of senior positions in the mutual funds sector, including within sales, operations and client servicing functions. He has also served as a Director on a variety of boards for previous employers and this, combined with his functional experience, gives him a strong background in sales generation, relationship management and corporate strategy which will help drive the development of the brands owned by ICE.'
        },
        {
            image: georgina,
            name: 'Georgina Buesnel, BSC(Hons), ACIB, TEP, Int.Dip (AML)',
            role: 'Non-Executive Director',
            description: 'Georgina has 38 years’ experience in the financial services industry that has included working with trust companies and banking businesses. Having held the position as a director of ICE since its incorporation in 2011, her experience and knowledge are invaluable in supporting ICE’s day to day business activities including acting as Compliance Officer.'
        },
        {
            image: no_picture,
            width: '267px',
            name: 'Jonathon Buesnel, ACG, MCMI',
            role: 'Non-Executive Director',
            description: 'For the past 29 years, Jon has developed his expertise within financial services working for a number of trust companies in client servicing, company secretarial and operations roles. He serves as a director for a number of regulated financial services companies and is also the company secretary of a regulated trust company. Jon qualified as a chartered secretary in 2003 and holds a diploma from the Chartered Management Institute.'
        },
    ]
}

export const brands = {
    title: 'Our Brands',
    content: [
        {
            logo: bikeLogo,
            leftImage: bike,
            text: `As the world’s first ever comparison website dedicated to motorbike insurance, The Bike Insurer is a pioneer in its field. Having helped hundreds of thousands of bikers compare insurance quotations over the years, our experience in this field is unrivalled. We work with some of the UK's most successful and established insurance brokers to bring bikers high quality, reliable insurance policies at competitive market prices. 
            Built around passion, the brand was created for bikers, by bikers and even today, has a great affinity with biker clubs, charities and events.`,
            ctaLabel: 'Visit website',
            ctaUrl: 'https://www.thebikeinsurer.co.uk/'
        },
        {
            logo: vanLogo,
            leftImage: van,
            text: `The Van Insurer is a dedicated van insurance comparison website that is committed to making van insurance easy, fast and affordable.
            Following its launch in 2008, the site has remained competitive within the market thanks to the high quality panel of insurance brokers it works with. Some of the UK's most successful names in van insurance are on hand, 24 hours a day through our online portal, to quote for your van insurance.`,
            ctaLabel: 'Visit website',
            ctaUrl: 'https://www.thevaninsurer.co.uk/'
        },
        {
            logo: motorhomeLogo,
            leftImage: motorhome,
            text: `Launched in 2021, The Motorhome Insurer extends ICE’s range of insurance comparison websites to the motorhome market, bringing with it the ability for consumers to select their insurance provider from a range of providers offering some of the most competitive premiums in the market. The Motorhome Insurer draws on ICE's heritage of helping customers access high quality, affordable insurance quotations in minutes through its online portal meaning customers can get back to focussing on the things in life they enjoy, like spending time in their motorhome!`,
            ctaLabel: 'Visit website',
            ctaUrl: 'https://www.themotorhomeinsurer.co.uk/'
        },
    ]
}

export const homeInfo = {
    title: 'Who is ICE?',
    leftSide: [
        {
            text: 'Since its foundation in 2011, ICE has aimed to deliver a market leading insurance price comparison service for UK consumers looking for motorcycle insurance. In subsequent years, we expanded our range of products to provide similar consumer solutions to those looking for van insurance, motorhome insurance and caravan insurance. Regulated by the Jersey Financial Services Commission and operating in the UK under an Appointed Representative status, ICE’s ownership and management of its various websites has seen the company progressively develop its product suite to meet the demands of UK consumers in the automotive insurance sector.'
        },
        {
            text: 'At ICE, we recognise that the process of buying insurance can be complicated and very time consuming. It is also a forced expense that only protects against a possible eventuality and so the thought of spending hours searching for the right policy at the right price is a luxury that many of us cannot afford. Our aim is to provide customers with simple, quick and cost-effective solutions that leave them feeling happy and satisfied with their purchasing experience, safe in the knowledge that they have brought from a reputable insurer.'
        },
    ],
    rightSide: [
        {
            text: 'We have partnered with Vast Visibility, a leading provider of technology solutions to the UK insurance sector. Together we monitor market trends and consumer feedback to ensure that our customer journey remains as smooth and easy-to-use as possible, so that the buying experience is the best we can make it. All insurers providing quotations through our website are regulated entities operating to the highest standards, so giving our customers peace of mind from the outset and at the conclusion of their insurance buying journey.'
        }
    ]
}